import styled from '@emotion/styled';
import { SPACER, MEDIA_QUERIES, GRID } from 'radiance-ui/lib/constants';

import { LOCAL_TYPOGRAPHY_CONSTANTS } from 'styles/constants';

export const InnerBox = styled.div`
  max-width: ${GRID.xlContentMaxWidth};
  margin: 0 auto;
  padding: 0 0 0 ${SPACER.xlarge};

  ${MEDIA_QUERIES.mdUp} {
    padding: 0 ${SPACER.xlarge};
  }
`;

export const Title = styled.h1`
  padding: 128px 0;
  line-height: 1.2;
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.titleXlg};
  color: ${({ theme }) => theme.COLORS.primary};
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};

  ${MEDIA_QUERIES.mdUp} {
    padding: 178px 0;
  }
`;

export const SectionsBox = styled.div`
  width: 100%;
  margin: 0 auto 0 0;
  padding: ${SPACER.x4large} 21% ${SPACER.x4large} 0;

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x4large} 0;
    width: 72%;
  }
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.COLORS.primary};
  font-size: ${LOCAL_TYPOGRAPHY_CONSTANTS.fontSize.headerBody};
  line-height: 1.5;

  a,
  p {
    color: ${({ theme }) => theme.COLORS.primaryTint2};
    cursor: pointer;
  }
`;
