import { useEffect, useState } from 'react';

import { BRAND } from 'consts/metricsTrackingEvents';

declare global {
  interface Window {
    duet?: {
      updateStyle: (style: { [key: string]: string | number }) => void;
      setEventListener: (
        listener: (event: { eventName: string }) => void,
      ) => void;
      open: () => void;
      isReady: () => boolean;
    };
  }
}

function adjustPosition() {
  window.duet?.updateStyle({
    bottom: '45px',
    // Allow modals + cookie dialog to be above chatbot, otherwise, scrolling breaks in iOS.
    zIndex: 200,
  });

  if (
    window.innerWidth <= 768 &&
    (window.location.pathname === '/products/custom-formula-acne/' ||
      window.location.pathname === '/products/future-proof-anti-aging/')
  ) {
    window.duet?.updateStyle({
      bottom: '185px',
    });

    window.duet?.setEventListener((event) => {
      const { eventName } = event;

      if (eventName === 'DUET_INITIALIZE') {
        window.duet?.updateStyle({
          bottom: '0',
        });
      } else if (eventName === 'DUET_DEINITIALIZE') {
        window.duet?.updateStyle({
          bottom: '185px',
        });
      }
    });
  }
}

export const DecagonChatbot = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (document.getElementById('decagon-script')) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('id', 'decagon-script');
    script.src = `https://decagon.ai/loaders/${BRAND}.js`;
    script.async = true;

    document.body.appendChild(script);

    const promiseThen = new Promise((resolve) => {
      setTimeout(() => {
        if (window?.duet?.isReady()) {
          resolve(true);
        }
      }, 1000);
    });

    promiseThen
      .then(() => {
        setIsReady(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    adjustPosition();
  }, [isReady]);

  return null;
};
