import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const SectionBox = styled.div<{ withBackImage?: boolean }>`
  padding: ${SPACER.xlarge} 0;
  position: relative;

  &:not(:first-of-type) {
    border-top: 2px solid ${LOCAL_COLORS.contactUsBorder};
  }
`;

export const titleText = (theme: ThemeType) => css`
  letter-spacing: 1px;
  color: ${theme.COLORS.primary};
  text-transform: uppercase;
`;
