import React from 'react';

import { SectionBox, titleText } from './style';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <SectionBox>
    <p>
      <strong css={titleText}>{title}</strong>
    </p>
    {children}
  </SectionBox>
);

export default Section;
